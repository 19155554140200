import React from 'react';
import './App.css';

export default function App(){

    const aEquipas = () => {

        const shuffledArray         = Jogadores.sort((a, b) => 0.5 - Math.random());

        const jogadoresMasculinos   = shuffledArray.filter(jogador => jogador.genero === 'M');
        const jogadoresFemininos    = shuffledArray.filter(jogador => jogador.genero === 'F');

        const aEquipas              = [];

        while (jogadoresMasculinos.length > 0 && jogadoresFemininos.length > 0) {

            const jogadorMasculino  = jogadoresMasculinos.pop();
            const jogadorFeminino   = jogadoresFemininos.pop();

            const aEquipa           = [jogadorMasculino, jogadorFeminino];
            aEquipas.push(aEquipa);
        }

        while (jogadoresMasculinos.length >= 2) {

            const aEquipa           = [jogadoresMasculinos.pop(), jogadoresMasculinos.pop()];
            aEquipas.push(aEquipa);
        }

        while (jogadoresFemininos.length >= 2) {

            const aEquipa = [jogadoresFemininos.pop(), jogadoresFemininos.pop()];
            aEquipas.push(aEquipa);
        }

        return aEquipas;
    };

    return (
        <div className="App">
            <div className="box">
                <h1 className="has-text-centered title is-2 mb-6">Sorteio das equipas - Padel</h1>

                <div className="block">
                    <h1 className="has-text-centered title is-3">Jogadores</h1>
                </div>
                <div className="columns is-multiline mb-6">
                    
                    { Jogadores.map(function(el, i){
                        return (
                            <div className="column is-one-fifth" key={i}>
                                <div className="box">
                                    <p>- { el.nome }</p>
                                </div>
                            </div>
                        )
                    }) }
                </div>

                <div className="block">
                    <h1 className="has-text-centered title is-4">Equipas</h1>
                </div>
                <div className="columns is-multiline mt-6">
                    { aEquipas().map(function(el, i){
                        return (
                            <div className="column is-one-fifth" key={i}>
                                <div className="box">
                                    <h3><strong>Equipa { i + 1}</strong></h3>
                                    <p>- { el[0].nome }</p>
                                    <p>- { el[1].nome }</p>
                                </div>
                            </div>
                        )
                    }) }
                </div>
            </div>
        </div>
    );
}

const Jogadores         = [
    {id: 1, nome: 'Rui Cardoso', genero: 'M'},
    {id: 2, nome: 'Adriana Alves', genero: 'F'},
    {id: 3, nome: 'Joel Silva', genero: 'M'},
    {id: 4, nome: 'Saullo Souza', genero: 'M'},
    {id: 5, nome: 'Ricardo Torres', genero: 'M'},
    // {id: 6, nome: 'Patrick Pereira', genero: 'M'},
    {id: 7, nome: 'Sergio Vale', genero: 'M'},
    {id: 8, nome: 'Barbara Ribeiro', genero: 'F'},
    {id: 9, nome: 'Carlos Dias', genero: 'M'},
    {id: 10, nome: 'Diogo Machado', genero: 'M'},
    {id: 11, nome: 'Ricardo Silva', genero: 'M'},
    {id: 12, nome: 'Carla Matias', genero: 'F'},
    {id: 13, nome: 'Barbara Gomes', genero: 'F'},
    {id: 14, nome: 'Eduarda Fernandes', genero: 'F'},
    {id: 15, nome: 'Vitoria Castro', genero: 'F'},
    {id: 16, nome: 'Angela Lemos', genero: 'F'},
    {id: 17, nome: 'Barbara Amaral', genero: 'F'},
    {id: 18, nome: 'Beatriz Eiras', genero: 'F'},
    {id: 19, nome: 'Henrique Torres', genero: 'M'},
    {id: 20, nome: 'Carlos Carvalho', genero: 'M'},
    {id: 21, nome: 'Hugo Gonçalves', genero: 'M'},
];